@tailwind base;
@tailwind components;
@tailwind utilities;


  
body {
	background-color: black;
	color: #A3A3A3;
	
}
ol { counter-reset: item }
li{ display: block }
li:before { 
	content: counters(item, ".") ". "; 
	counter-increment: item; 
	font-weight: bold; 
	color: white;
}

  
  ol > li {
	display: table;
	margin-bottom: 0.6em;
  }
  
  ol > li:before {
	display: table-cell;
	padding-right: 0.6em;    
  }
  
  li ol > li {
	margin: 0;
  }
  

@layer utilities {
	.shadow-gradient-default {
		box-shadow: 0 1px 6px 0 rgba(247, 152, 106, 0.8);
	}

	.shadow-gradient-white {
		box-shadow: 0 1px 6px 0 rgba(217, 217, 217, 0.8);
	}

	.shadow-gradient-black {
		box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0);
	}

	.button-gradient-default {
		background: linear-gradient(90deg, rgb(255, 30, 165) 0%, rgb(245, 184, 90) 100%);
	}

	.button-gradient-revers {
		background: linear-gradient(90deg, rgb(245, 184, 90) 0%, rgb(255, 30, 165) 100%);
	}

	.border-gradient-blue {
		border-image: linear-gradient(120deg, rgb(22, 229, 242) 0%, rgb(87, 250, 187) 100%) 1;
	}

	.border-gradient-green {
		border-image: linear-gradient(90deg, rgb(180, 245, 122) 0%, rgb(83, 254, 143) 100%) 1;
	}

	.border-gradient-green-1 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(83, 254, 143) 20%) 1;
	}

	.border-gradient-green-2 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(83, 254, 143) 20%, rgb(255, 238, 106) 80%, rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-green-red {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(251, 110, 128) 20%, rgb(83, 254, 143) 80%, rgb(0, 0, 0) 100%) 1;
	}

	.text-gradient-default {
		background: linear-gradient(90deg, rgb(255, 30, 165) 0%, rgb(245, 184, 90) 100%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-yellow {
		background: linear-gradient(120deg, #FFEC6D 0%, #FB8FC2 100%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-purple {
		background: linear-gradient(120deg, rgb(209, 126, 253) 0%, rgb(244, 175, 182) 100%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-purple-1 {
		background: linear-gradient(91deg, rgb(209, 126, 253) 0%, rgb(244, 175, 182) 50%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-green {
		background: linear-gradient(91deg, rgb(121, 251, 135) 25%, rgb(35, 252, 198) 80%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-green-red {
		background: linear-gradient(90deg, rgb(255, 35, 163) 40%, rgb(247, 174, 96) 60%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-blue {
		background: linear-gradient(91deg, rgb(22, 229, 242) 0%, rgb(87, 250, 187) 100%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-blue-1 {
		background: linear-gradient(120deg, rgb(22, 229, 242) 0%, rgb(87, 250, 187) 100%);
		background-clip: text;
		color: transparent;
	}

	.text-gradient-orange-1 {
		background: linear-gradient(120deg, rgb(255, 239, 185) 0%, rgb(255, 193, 107) 100%);
		background-clip: text;
		color: transparent;
	}

	.symbol-gradient {
		background: linear-gradient(rgb(254, 169, 74) 0%, rgb(86, 241, 167) 100%);
		background-clip: text;
		color: transparent;
	}

	.border-white-1 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(253, 253, 253) 12px, rgb(253, 253, 253) calc(100% - 12px), rgb(0, 0, 0) 100%) 1;
	}

	.border-white-2 {
		border-image: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(253, 253, 253) 12px, rgb(253, 253, 253) calc(100% - 12px), rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-1 {
		border-image: linear-gradient(0deg, rgb(83, 238, 163) 0%, rgb(180, 245, 122) calc(100% - 24px), rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-2 {
		border-image: linear-gradient(90deg, rgb(87, 251, 180) 0%, rgb(83, 238, 163) 100%) 1;
	}

	.border-gradient-3 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(73, 236, 246) 24px, rgb(180, 245, 122) calc(100% - 24px), rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-4 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(118, 248, 251) 24px, rgb(87, 251, 180) 100%) 1;
	}

	.border-gradient-5 {
		border-image: linear-gradient(0deg, rgb(135, 217, 253) 0%, rgb(118, 248, 251) calc(100% - 24px), rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-6 {
		border-image: linear-gradient(0deg, rgb(174, 154, 253) 0%, rgb(135, 217, 253) 100%) 1;
	}

	.border-gradient-7 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(190, 132, 253) 24px, rgb(118, 248, 251) calc(100% - 24px), rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-8 {
		border-image: linear-gradient(90deg, rgb(174, 154, 253) 0%, rgb(190, 132, 253) 100%) 1;
	}

	.border-gradient-9 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(234, 117, 253) 24px, rgb(190, 132, 253) 100%) 1;
	}

	.border-gradient-10 {
		border-image: linear-gradient(0deg, rgb(234, 117, 253) calc(100% - 24px), rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-11 {
		border-image: linear-gradient(0deg, rgb(243, 169, 189) 0%, rgb(234, 117, 253) 100%) 1;
	}

	.border-gradient-12 {
		border-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(180, 245, 122) 24px, rgb(234, 117, 253) calc(100% - 24px), rgb(0, 0, 0) 100%) 1;
	}

	.border-gradient-13 {
		border-image: linear-gradient(90deg, #B4F57A 0%, #F3A9BD 100%) 1;
	}

	.border-gradient-14 {
		border-image: linear-gradient(0deg, #000000 0%, #B4F57A 24px) 1;
	}

	.border-gradient-15 {
		border-image: linear-gradient(0deg, #B4F57A 0%, #B4F57A 20%, #FFEE6A calc(100% - 24px), #000000 100%) 1;
	}
	
	.container {
		width: 100%; 
		margin: 0 auto;
		max-width: 1300px;
	}

	.hide-scrollbar::-webkit-scrollbar {
		display: none;
	  }
	  
	  .hide-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	  }
}

